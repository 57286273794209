var isSP = function() {
	return $('.sp-view:visible').length != 0;
};

// Retina対応
document.createElement('picture');

// scroll
$(function(){
    var headerHeight = 100;
		var headerHeight2 = 60;
    var speed = 800;
    var urlHash = location.hash;
    if(urlHash) {
        $('html,body').stop().scrollTop(0);
        setTimeout(function(){
            var target = $(urlHash);
            var position = target.offset().top - headerHeight;
            $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
        }, 500);
    }
	
		if (isSP()) {
			if(urlHash) {
					$('html,body').stop().scrollTop(0);
					setTimeout(function(){
							var target = $(urlHash);
							var position = target.offset().top - headerHeight2;
							$('body,html').stop().animate({scrollTop:position}, speed, 'swing');
					}, 500);
			}
		}
	
    $('a[href*="#"]' + 'a:not(.non-scroll)').click(function() {
        var href= $(this).attr("href");
        var split = href.split('#');
        var target = $(split[1] == '' ? 'html' : "#" + split[1]);
        var position = target.offset().top - headerHeight;
        var position2 = target.offset().top - headerHeight2;
        $('html,body').stop().animate({scrollTop:position}, speed, 'swing');   
			
				if (isSP()) {
					$('html,body').stop().animate({scrollTop:position2}, speed, 'swing');   
				}
    });
});

// pagetop
$(function() {
	var topBtn = $('.pagetop');	
	topBtn.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});
    topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
    });
});

// SP header menu
$(function() {
	$('.header-inner__hnav').on('click', function() {
			$(this).toggleClass('active');
			$('body').toggleClass('show');
	});
	
	$('.header-inner__gnav__btn').on('click', function() {
		$('body').removeClass('show');
		$('body,html').css({"overflow":"visible","height":"auto"});
		$(".header-inner__hnav").removeClass('active');
	});
	
	$('.header-inner__gnav__inner a[href*="#"]').on('click', function(){
			if (isSP()) {
				$(".header-inner__hnav").removeClass('active');
				$("body").removeClass('show');
			}
	});
});

// accordion
$(function() {
	$('.accordion').click(function() {
		$(this).next().slideToggle();
		$(this).toggleClass('active');
	});
	
	$('.sp-accordion').click(function() {
		if (isSP()) {
			$(this).next().slideToggle();
			$(this).toggleClass('active');
		}
	});
});

// graduates
$(function() {
	$('.graduates-head').click(function() {
		$(this).next().slideToggle();
		$(this).children('.graduates-head__icon').toggleClass('active');
	});
});

// PC header
$(function() {
  var $win = $(window),
      $header = $('header'),
      animationClass = 'is-animation';

  $win.on('load scroll', function() {
    var value = $(this).scrollTop();
    if ( value > 100 ) {
      $header.addClass(animationClass);
    } else {
      $header.removeClass(animationClass);
    }
  });
});

// tel link
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
    $('a[href^="tel:"]').on('click', function(e) {
        e.preventDefault();
    });
}